<script>
import { SuawSeriesSummary } from "@/components";
import { GetEventSeriesSummary, GetUserEventSeriesMembershipStatus } from "../../operations.gql";
import { SeriesScheduleFromICalText } from "../../common.js";
import * as SeriesApi from "../../api";
import SeriesSummarySkeleton from "./SeriesSummarySkeleton.vue";
import SeriesSubscribeModal from "./SeriesSubscribeModal.vue";

export default {
  name: "SeriesSummaryPipe",
  components: { SuawSeriesSummary, SeriesSubscribeModal, SeriesSummarySkeleton },
  props: {
    seriesId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hasLoadedSeries: false,
      hasLoadedMembership: !this.$auth.isAuthenticated,
      resultGetEventSeriesSummary: {
        id: "",
        title: "",
        ical_text: "",
        duration: "",
        chapter_id: "",
        organizers: [
          // {
          //   user_id: ""
          // }
        ],
        membership_count: null
      },
      resultGetUserEventSeriesMembershipStatus: [
        // {
        //   id: ""
        // }
      ],
      showModal: false
    };
  },
  apollo: {
    resultGetEventSeriesSummary: {
      query: GetEventSeriesSummary,
      variables() {
        return {
          seriesId: this.seriesId
        };
      },
      skip() {
        return !this.seriesId;
      },
      result({ loading }) {
        this.hasLoadedSeries = !loading;
      }
    },
    resultGetUserEventSeriesMembershipStatus: {
      query: GetUserEventSeriesMembershipStatus,
      variables() {
        return {
          seriesId: this.seriesId,
          userId: this.$auth.user.id
        };
      },
      skip() {
        return !this.$auth.isAuthenticated;
      },
      result({ loading }) {
        this.hasLoadedMembership = !loading;
      }
    }
  },
  computed: {
    isLoading() {
      return !this.hasLoadedSeries || !this.hasLoadedMembership;
    },
    hasSeries() {
      return this.resultGetEventSeriesSummary.id !== "";
    },
    series() {
      return this.hasSeries ? this.resultGetEventSeriesSummary : null;
    },
    isSubscribed() {
      return this.resultGetUserEventSeriesMembershipStatus?.length > 0;
    },
    organizerIds() {
      return this.series ? this.series.organizers.map(org => org.user_id) : [];
    },
    isOrganizer() {
      if (this.$auth.isAdmin) {
        return true;
      } else if (this.organizerIds && this.$auth.isAuthenticated) {
        return this.organizerIds.some(id => id === this.$auth.user.id);
      }
      return false;
    },
    seriesSchedule() {
      return this.hasSeries ? SeriesScheduleFromICalText(this.series?.ical_text, this.series?.duration) : null;
    },
    frequency() {
      return this.seriesSchedule ? this.seriesSchedule.weeklyFrequencyText : null;
    },
    title() {
      return this.hasSeries ? this.series.title : "";
    },
    seriesMemberCount() {
      return this.hasSeries ? this.series.membership_count.aggregate.count : null;
    }
  },
  async mounted() {
    const { action: a } = this.$route.query;
    if (a === "subscribe") {
      this.showModal = true;
      const query = { ...this.$route.query };
      delete query["action"];
      this.$router.replace({ path: this.$route.path, query }); // Use replace to avoid full navigation
    }
  },
  methods: {
    onSubscribeClick() {
      this.showModal = true;
    },
    async addSubscription() {
      const result = await SeriesApi.addEventSubscription(this.seriesId, this.$auth.user.id, this.isOrganizer);
      this.handleSubscriptionResult(result);
    },
    async removeSubscription() {
      const result = await SeriesApi.removeEventSubscription(this.seriesId, this.$auth.user.id);
      this.handleSubscriptionResult(result);
    },
    async handleSubscriptionResult(result) {
      this.showModal = false;
      if (result.success) {
        await this.$apollo.queries.resultGetUserEventSeriesMembershipStatus.refetch();
        await this.$apollo.queries.resultGetEventSeriesSummary.refetch();
        this.$emit("toggle-join");
      } else {
        this.$root.$emit("universal-error-message", result.error);
      }
    },
    onEditSeriesClick() {
      this.$router.push({
        name: "SeriesEdit",
        params: {
          chapterId: this.series.chapter_id,
          seriesId: this.seriesId
        },
        query: {
          step: "schedule"
        }
      });
    },
    redirectToSignIn() {
      const redirectUrl = `${this.$route.fullPath}${this.$route.fullPath.includes("?") ? "&" : "?"}action=subscribe`;
      this.$router.push({
        name: "LogIn",
        query: {
          redirectUrl
        }
      });
    },
    onCancelModal() {
      this.showModal = false;
    }
  }
};
</script>

<template>
  <div class="suaw-series-summary-container">
    <SeriesSubscribeModal
      :value="showModal"
      :is-subscribed="isSubscribed"
      :is-authenticated="$auth.isAuthenticated"
      @close="showModal = false"
      @subscribe="addSubscription"
      @unsubscribe="removeSubscription"
      @redirect-to-sign-in="redirectToSignIn"
    />
    <SeriesSummarySkeleton v-if="isLoading" />
    <SuawSeriesSummary
      v-else
      :title="title"
      :is-subscribed="isSubscribed"
      :member-count="seriesMemberCount"
      :is-organizer="isOrganizer"
      :frequency="frequency"
      @subscribe-click="onSubscribeClick"
      @edit-series-click="onEditSeriesClick"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-series-summary-container {
  width: 100%;
}
</style>
