<script>
import { SuawList, SuawInputGroup, SuawLabel, SuawEmptyState } from "@/components";
import ListItemSkeleton from "./ListItemSkeleton.vue";
import { GetOrganizersByIds } from "../operations.gql";

export default {
  name: "OrganizerSidebar",
  components: {
    SuawLabel,
    SuawList,
    SuawInputGroup,
    SuawEmptyState,
    ListItemSkeleton
  },
  props: {
    headerText: {
      type: String,
      required: true
    },
    organizerIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      loadingQueriesCount: 0,
      resultGetOrganizersByIds: [
        // {
        //   id: "",
        //   display_name: "",
        //   avatar_url: "",
        //   avatar_color: ""
        //   is_avatar_hidden: false
        // }
      ]
    };
  },
  apollo: {
    resultGetOrganizersByIds: {
      query: GetOrganizersByIds,
      variables() {
        return {
          ids: this.organizerIds
        };
      },
      skip() {
        return !this.organizerIds.length;
      },
      fetchPolicy: "no-cache",
      result() {
        this.isLoading = false;
      }
    }
  },
  computed: {
    listItems() {
      return this.resultGetOrganizersByIds.map(org => ({
        id: org.id,
        text: org.deleted_at !== null ? "Community Member" : org.display_name,
        userId: org.id,
        buttonText: "Send Message",
        buttonIcon: "IconComment"
      }));
    }
  },
  methods: {
    handleMessageClick(item) {
      const otherUserId = item.id;
      this.$root.$emit("open-direct-message", { otherUserId: otherUserId });
    }
  }
};
</script>

<template>
  <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true" direction="column">
    <SuawLabel size="large" :label-text="headerText" weight="bold" />
    <template v-if="isLoading">
      <ListItemSkeleton />
    </template>
    <SuawList v-else-if="listItems.length > 0" :items="listItems" @button-click="handleMessageClick" />
    <SuawEmptyState v-else size="medium" message="No Organizers" />
  </SuawInputGroup>
</template>
