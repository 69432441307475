<script>
import ReportEventView from "./ReportEventView.vue";
import * as SeriesApi from "@/features/series/api.js";

export default {
  name: "ReportEventPipe",
  components: {
    ReportEventView
  },
  props: {
    eventOccurrenceId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isModalOpen: false
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    handleClose() {
      this.isModalOpen = false;
    },
    async handleSubmit(report) {
      const result = await SeriesApi.reportEventProblem(this.eventOccurrenceId, report.problemType, report.comment);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
        return;
      }
      this.$root.$emit("universal-success-message", "Thank you for reporting the problem. We will review it soon.");
      this.handleClose();
    }
  }
};
</script>

<template>
  <div>
    <ReportEventView
      v-model="isModalOpen"
      @close="handleClose"
      @submit="handleSubmit"
    />
    <div class="report-event-container">
      <a href="#" class="report-link" @click.prevent="openModal">Report an issue</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.report-event-container {
  margin: 0;
  padding: 0;
}

.report-link {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }
}
</style>
