<script>
export default {
  name: "EventDescriptionSkeleton"
};
</script>

<template>
  <div class="suaw-event-description-skeleton">
    <div class="suaw-event-description-skeleton__paragraph"></div>
    <div class="suaw-event-description-skeleton__paragraph"></div>
    <div class="suaw-event-description-skeleton__paragraph short"></div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-event-description-skeleton {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 70px;
  width: 100%;

  &__label {
    width: 150px;
    height: 25px;
    border-radius: 4px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  &__paragraph {
    width: 100%;
    height: 14px;
    border-radius: 4px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  &__paragraph.short {
    width: 80%;
  }

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
}
</style>
