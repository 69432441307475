<script>
import { SuawModalTwo, SuawHeading, SuawButton } from "@/components";

export default {
  name: "EventRsvpModal",
  components: { SuawModalTwo, SuawHeading, SuawButton },
  props: {
    value: Boolean,
    isRsvped: {
      type: Boolean,
      required: true
    },
    isAuthenticated: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    modalTitle() {
      if (!this.isAuthenticated) {
        return "You must be signed in to RSVP to an event. You'll be redirected to the sign-in page.";
      }
      if (this.isRsvped) {
        return "If you un-RSVP, you will no longer receive email reminders or updates about this event.";
      }
      return "By RSVPing, you'll receive a reminder 24 hours before the event and notifications about any changes. If you'd like email reminders about future events in this series, please Subscribe to the series.";
    }
  },
  methods: {
    onCancel() {
      this.$emit("close");
    },
    onConfirm() {
      this.$emit("close");
      if (!this.isAuthenticated) {
        this.$emit("redirect-to-sign-in");
      } else {
        this.$emit(this.isRsvped ? "unrsvp" : "rsvp");
      }
    }
  }
};
</script>

<template>
  <SuawModalTwo :value="value" size="medium" @input="$emit('close')">
    <div class="modal-content">
      <SuawHeading level="4" :content="modalTitle" />
      <div class="modal-actions">
        <SuawButton class="modal-button" size="large" type="secondary-outline" icon-left="" button-text="Cancel" @click="onCancel" />
        <SuawButton class="modal-button" size="large" type="primary" icon-left="" :button-text="!isAuthenticated ? 'Sign In' : 'Confirm'" @click="onConfirm" />
      </div>
    </div>
  </SuawModalTwo>
</template>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-triple);
  text-align: center;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: var(--sem-space-triple);
}

.modal-button {
  width: 100%;
}
</style>
