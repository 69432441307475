<script>
import { SuawButton } from "@/components";
export default {
  name: "EventNavigationSkeleton",
  components: { SuawButton }
};
</script>

<template>
  <div class="suaw-event-navigation-skeleton">
    <SuawButton class="suaw-button--no-x-pd prev-button" size="large" type="ghost" icon-left="IconChevronBigLeft" icon-size="extra-large" button-text="" disabled />
    <div class="event-date-skeleton"></div>
    <SuawButton class="suaw-button--no-x-pd next-button" size="large" type="ghost" icon-right="IconChevronBigRight" icon-size="extra-large" button-text="" disabled />
  </div>
</template>

<style lang="scss" scoped>
.suaw-event-navigation-skeleton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;

  .prev-button,
  .next-button {
    background: none;
    border: none;
    cursor: not-allowed;
    font-size: 24px;
  }

  .event-date-skeleton {
    width: 130px;
    height: 38px;
    border-radius: 4px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
}
</style>
