<template>
  <div class="skeleton-event-card">
    <div class="skeleton-event-card__summary-container">
      <!-- Calendar Date Placeholder -->
      <div class="skeleton skeleton-event-card__calendar"></div>

      <div class="skeleton-event-card__summary-event-info">
        <!-- Event Time -->
        <div class="skeleton skeleton-text skeleton-event-card__time"></div>
        <!-- Venue Title -->
        <div class="skeleton skeleton-text skeleton-event-card__title"></div>
      </div>

      <div class="skeleton-event-card__summary-buttons-container">
        <!-- Buttons -->
        <!-- <div class="skeleton skeleton-event-card__button"></div> -->
        <div class="skeleton skeleton-event-card__button"></div>
      </div>
    </div>

    <!-- Event Status / Location Placeholder -->
    <div class="skeleton-event-card__location-container">
      <div class="skeleton skeleton-event-card__block"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkeletonEventCard"
};
</script>

<style lang="scss" scoped>
.skeleton-event-card {
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-md);
  padding: var(--sem-space-double);
  width: 100%;
  max-width: 1264px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;

  &__summary-container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    row-gap: 12px;
    column-gap: 16px;
    align-items: center;
  }

  &__calendar {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    grid-column: 1/2;
    grid-row: 1/3;
    @media (max-width: 650px) {
      grid-column: 1/2;
      grid-row: 1/2;
    }
  }

  &__summary-event-info {
    display: grid;
    row-gap: 5px;
    width: 100%;
    // align-self: flex-start;
    justify-content: flex-start;
    grid-column: 2/3;
    grid-row: 1/3;
    @media (max-width: 650px) {
      grid-column: 2/3;
      grid-row: 1/2;
    }
    @media (max-width: 525px) {
      grid-column: 2/4;
    }
  }

  &__summary-buttons-container {
    display: inline-flex;
    justify-content: flex-end;
    gap: 12px;
    @media (max-width: 650px) {
      grid-column: 1/4;
      grid-row: 2/3;
      flex-direction: column-reverse;
      width: 100%;
    }
    @media (max-width: 525px) {
      grid-row: 3/4;
    }
  }

  &__location-container {
    height: 315px;
    border-radius: 8px;
    overflow: hidden;
  }

  /* Skeleton Effects */
  .skeleton {
    background: linear-gradient(90deg, #f4f4f4 25%, #eaeaea 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
    border-radius: 4px;
  }

  &__text {
    height: 20px;
    width: 80%;
  }

  &__time {
    width: 180px;
    height: 26px;
  }

  &__title {
    height: 23px;
    width: 120px;
  }

  &__button {
    width: 116px;
    height: 48px;
    border-radius: 8px;
    @media (max-width: 650px) {
      grid-row: 3/4;
      width: 100%;
    }
  }

  &__block {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
}
</style>
