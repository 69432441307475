<script>
import { SuawForm, SuawModalTwo, SuawRadio, SuawTextArea, SuawButton, SuawInputGroup } from "@/components";

export default {
  name: "ReportEventView",
  components: {
    SuawForm,
    SuawModalTwo,
    SuawRadio,
    SuawTextArea,
    SuawButton,
    SuawInputGroup
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: {
        problemType: "",
        comment: ""
      },
    };
  },
  computed: {
    showDescription() {
      return this.form.problemType === "Other";
    }
  },
  methods: {
    handleClose() {
      this.$emit("close");
      // Reset form
      this.form.problemType = "";
      this.form.comment = "";
    },
    handleSubmit() {
      const report = {
        problemType: this.form.problemType,
        comment: this.showDescription ? this.form.comment : ""
      };
      this.$emit("submit", report);
    }
  }
};
</script>

<template>
  <SuawModalTwo :value="value" size="medium" @input="$emit('close')">
    <div class="modal-content">
      <SuawForm>
        <template #form>
          <SuawInputGroup direction="column" group-gap="base">
            <SuawRadio
              id="no-start"
              v-model="form.problemType"
              name="reportReason"
              label="The host never started the event"
              native-value="NoStart"
            />
            <SuawRadio
              id="broken-link"
              v-model="form.problemType"
              name="reportReason"
              label="I couldn't join due to a broken or incorrect link"
              native-value="BrokenLink"
            />
            <SuawRadio
              id="tech-issues"
              v-model="form.problemType"
              name="reportReason"
              label="Technical difficulties prevented access"
              native-value="TechnicalDifficulty"
            />
            <SuawRadio
              id="other"
              v-model="form.problemType"
              name="reportReason"
              label="Other (please describe)"
              native-value="Other"
            />
          </SuawInputGroup>
          <SuawTextArea
            v-if="showDescription"
            v-model="form.comment"
            name="description"
            placeholder="Describe your issue here..."
          />
        </template>
      </SuawForm>
      <div class="modal-actions">
        <SuawButton class="modal-button" size="large" type="secondary-outline" icon-left="" button-text="Cancel" @click="handleClose" />
        <SuawButton class="modal-button" size="large" type="primary" icon-left="" button-text="Send" @click="handleSubmit" />
      </div>
    </div>
  </SuawModalTwo>
</template>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-triple);
  padding: var(--sem-space-triple);
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: var(--sem-space-triple);
}

.modal-button {
  width: 100%;
}


:deep(.suaw-textarea) {
  margin-bottom: 24px;
}

:deep(.suaw-radio) {
  text-align: left;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--sem-space-base);
  
  .suaw-radio__label {
    text-align: left;
    margin-left: var(--sem-space-base);
  }

  input[type="radio"] {
    margin-top: 4px;
  }
}
</style>
