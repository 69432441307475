<script>
import { SuawLabel, SuawList, SuawInputGroup } from "@/components";
import { GetChaptersByIds } from "../operations.gql";
export default {
  name: "ChapterSidebar",
  components: {
    SuawLabel,
    SuawList,
    SuawInputGroup
  },
  props: {
    headerText: {
      type: String,
      required: true
    },
    chapterIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      resultGetChaptersByIds: [
        // {
        //   id: "",
        //   slug: "",
        //   title: "",
        //   photo_url: ""
        // }
      ]
    };
  },
  apollo: {
    resultGetChaptersByIds: {
      query: GetChaptersByIds,
      variables() {
        return {
          ids: this.chapterIds
        };
      },
      skip() {
        return !this.chapterIds.length;
      }
    }
  },
  computed: {
    listItems() {
      return this.resultGetChaptersByIds.map(chapter => ({
        picture: chapter.photo_url,
        text: chapter.title,
        linkUrl: { name: "Chapter", params: { chapterId: chapter.id, chapterSlug: chapter.slug } },
        linkText: "View Chapter"
      }));
    }
  }
};
</script>

<template>
  <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true" direction="column">
    <SuawLabel size="large" :label-text="headerText" weight="bold" />
    <SuawList :items="listItems" />
  </SuawInputGroup>
</template>
