<script>
export default {
  name: "CalendarCardSkeleton"
};
</script>

<template>
  <div class="suaw-calendar-card-skeleton">
    <!-- Header (Month & Navigation) -->
    <div class="suaw-calendar-card-skeleton__header">
      <div class="suaw-calendar-card-skeleton__month shimmer"></div>
    </div>

    <!-- Weekday Labels -->
    <div class="suaw-calendar-card-skeleton__days">
      <div v-for="n in 7" :key="`day-${n}`" class="suaw-calendar-card-skeleton__day shimmer"></div>
    </div>

    <!-- Calendar Grid (Skeleton Cells) -->
    <div class="suaw-calendar-card-skeleton__grid">
      <div v-for="n in 35" :key="`cell-${n}`" class="suaw-calendar-card-skeleton__cell shimmer"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-calendar-card-skeleton {
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-md);
  padding: 16px;
  width: 100%;
  height: 317px; /* Fixed height */
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__header {
    margin-top: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__month {
    width: 100%;
    height: 28px;
    border-radius: 4px;
  }

  &__nav {
    display: flex;
    gap: 8px;
  }

  &__arrow {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  &__days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
    align-content: center;
    justify-items: center;
  }

  &__day {
    width: 28px;
    height: 28px;
    border-radius: 4px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
    flex-grow: 1;
    align-items: center;
    justify-items: center;
  }

  &__cell {
    width: 28px;
    height: 28px;
    border-radius: 4px;
  }

  /* Shimmer Effect */
  .shimmer {
    // background: linear-gradient(90deg, var(--sem-color-inactive-light) 25%, var(--sem-color-background-lightest) 50%, var(--sem-color-inactive-light) 75%);
    background: linear-gradient(90deg, #f4f4f4 25%, #eaeaea 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer-animation 1.5s infinite linear;
  }

  @keyframes shimmer-animation {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
}
</style>
