<script>
import { SuawInputGroup, SuawLabel, SuawParagraph } from "@/components";
import { GetEventDescriptionForDetailsById } from "../../operations.gql";
import EventDescriptionSkeleton from "./EventDescriptionSkeleton.vue";
export default {
  name: "EventDescription",
  components: {
    SuawInputGroup,
    SuawParagraph,
    SuawLabel,
    EventDescriptionSkeleton
  },
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultGetEventDescriptionForDetailsById: {
        description_json: { type: "doc", content: [{ type: "paragraph" }] }
      }
    };
  },
  apollo: {
    resultGetEventDescriptionForDetailsById: {
      query: GetEventDescriptionForDetailsById,
      variables() {
        return {
          eventId: this.eventId
        };
      },
      skip() {
        return !this.eventId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasEvent() {
      return this.resultGetEventDescriptionForDetailsById.id !== "";
    },
    event() {
      return this.hasEvent ? this.resultGetEventDescriptionForDetailsById : null;
    },
    description() {
      return this.hasEvent && this.event.description_json
        ? this.event.description_json
        : { type: "doc", content: [{ type: "paragraph", content: [{ type: "text", text: "No description available" }] }] };
    }
  }
};
</script>

<template>
  <SuawInputGroup direction="column" group-gap="double">
    <SuawLabel size="large" label-text="Event Description" weight="bold" />
    <EventDescriptionSkeleton v-if="isLoading || !hasEvent" />
    <SuawParagraph :text="description" />
  </SuawInputGroup>
</template>
