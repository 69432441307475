<script>
import { SuawEventNavigation } from "@/components";
import { UpcomingEventDates, GetStartTimeByEventId, UpcomingEvents } from "../../../events/operations.gql";
import { add, subMonths, startOfDay } from "date-fns";
import EventNavigationSkeleton from "./EventNavigationSkeleton.vue";
export default {
  name: "EventNavigationPipe",
  components: { SuawEventNavigation, EventNavigationSkeleton },
  props: {
    seriesId: {
      type: String,
      required: true
    },
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoadingGetStartTimeByEventId: true,
      isLoadingUpcomingEventDates: true,
      eventDates: [],
      asOfDate: "",
      selectedEventDate: null
    };
  },
  apollo: {
    resultGetStartTimeByEventId: {
      query: GetStartTimeByEventId,
      variables() {
        return {
          eventId: this.eventId
        };
      },
      skip() {
        return !this.eventId;
      },
      update(data) {
        this.selectedEventDate = this.setToUtcMidnight(data.resultGetStartTimeByEventId.starts_at);
      },
      result() {
        this.isLoadingGetStartTimeByEventId = false;
      }
    },
    resultUpcomingEventDates: {
      query: UpcomingEventDates,
      variables() {
        return {
          eventSeriesId: this.seriesId,
          timeZoneId: this.userTimeZone,
          asOfDate: this.getMonthAgoDate()
        };
      },
      update(data) {
        if (data.resultUpcomingEventDates.value.length > 0) {
          this.eventDates = data.resultUpcomingEventDates.value;
        }
      },
      skip() {
        return !this.seriesId;
      },
      result() {
        this.isLoadingUpcomingEventDates = false;
      }
    },
    resultUpcomingEvents: {
      query: UpcomingEvents,
      variables() {
        return {
          eventSeriesId: this.seriesId,
          asOfDate: this.asOfDate,
          untilDate: this.calculateUntilDate(this.asOfDate)
        };
      },
      update(data) {
        if (data.resultUpcomingEvents.value.length > 0) {
          this.$emit("event-selected", data.resultUpcomingEvents.value[0]);
        }
      },
      skip() {
        return !this.seriesId || !this.asOfDate;
      }
    }
  },
  computed: {
    isLoading() {
      return this.isLoadingGetStartTimeByEventId || this.isLoadingUpcomingEventDates;
    },
    userTimeZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone || "Etc/Universal";
    }
  },
  methods: {
    getMonthAgoDate() {
      const oneMonthAgo = subMonths(new Date(), 1);
      return new Date(Date.UTC(oneMonthAgo.getUTCFullYear(), oneMonthAgo.getUTCMonth(), oneMonthAgo.getUTCDate(), 0, 0, 0)).toISOString(); // Format as 'YYYY-MM-DDTHH:mm:ss'
    },
    setToUtcMidnight(dateString) {
      const date = new Date(dateString);
      const utcMidnight = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
      return utcMidnight.toISOString().slice(0, -5); // Removes ".000Z"
    },
    setToUserLocalMidnightUtc(date = new Date()) {
      return startOfDay(date).toISOString();
    },
    calculateUntilDate(isoString) {
      const date = new Date(isoString);
      const updatedDate = add(date, { hours: 24 });
      return updatedDate.toISOString();
    },
    onDateSelected(eventDate) {
      this.asOfDate = this.setToUserLocalMidnightUtc(new Date(eventDate));
    }
  }
};
</script>

<template>
  <EventNavigationSkeleton v-if="isLoading" />
  <SuawEventNavigation v-else :event-dates="eventDates" :selected-date="selectedEventDate" @date-selected="onDateSelected" />
</template>
