<script>
export default {
  name: "SeriesMemberCardSkeleton"
};
</script>

<template>
  <li class="suaw-series-member-card-skeleton">
    <div class="suaw-series-member-card-skeleton__avatar"></div>
    <div class="suaw-series-member-card-skeleton__name-location-container">
      <div class="suaw-series-member-card-skeleton__name"></div>
      <div class="suaw-series-member-card-skeleton__location"></div>
    </div>
    <div class="suaw-series-member-card-skeleton__status"></div>
    <div class="suaw-series-member-card-skeleton__chips">
      <div class="suaw-series-member-card-skeleton__chip"></div>
      <div class="suaw-series-member-card-skeleton__chip"></div>
    </div>
  </li>
</template>

<style lang="scss" scoped>
.suaw-series-member-card-skeleton {
  list-style-type: none;
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-md);
  padding: var(--sem-space-double);
  width: 100%;
  height: 144px;
  max-width: 1264px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 2fr 1fr;
  gap: 16px;
  @media (max-width: 650px) {
    padding: 8px;
  }

  &__avatar {
    grid-column: 1/2;
    grid-row: 1/3;
    width: 112px;
    height: 112px;
    border-radius: 50%;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  &__name-location-container {
    grid-column: 2/3;
    grid-row: 1/2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px;
  }

  &__name {
    width: 180px;
    height: 24px;
    border-radius: 4px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    animation: shimmer 1.5s infinite linear;
  }

  &__location {
    width: 180px;
    height: 14px;
    border-radius: 4px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    animation: shimmer 1.5s infinite linear;
  }

  &__status {
    width: 76px;
    height: 28px;
    border-radius: 4px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    animation: shimmer 1.5s infinite linear;
  }

  &__chips {
    grid-column: 2/3;
    grid-row: 2/3;
    display: flex;
    gap: 8px;
  }

  &__chip {
    width: 110px;
    height: 26px;
    border-radius: 16px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    animation: shimmer 1.5s infinite linear;
  }

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
}
</style>
