<script>
export default {
  name: "DiscussionItemSkeleton",
};
</script>

<template>
  <div class="suaw-discussion-item-skeleton">
    <div class="suaw-discussion-item-skeleton__header">
      <div class="suaw-discussion-item-skeleton__info">
        <div class="suaw-discussion-item-skeleton__avatar-chip"></div>
        <div class="suaw-discussion-item-skeleton__timestamp"></div>
      </div>
      <div class="suaw-discussion-item-skeleton__footer">
        <div class="suaw-discussion-item-skeleton__button"></div>
        <div class="suaw-discussion-item-skeleton__like"></div>
        <div class="suaw-discussion-item-skeleton__menu"></div>
      </div>
    </div>
    <div class="suaw-discussion-item-skeleton__content"></div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-discussion-item-skeleton {
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-md);
  padding: var(--sem-space-double);
  width: 100%;
  height: 110px;
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__avatar-chip {
    width: 100px;
    height: 25px;
    border-radius: 16px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__name {
    width: 100px;
    height: 16px;
    border-radius: 4px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  &__timestamp {
    width: 85px;
    height: 22px;
    border-radius: 4px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  &__content {
    width: 80%;
    height: 20px;
    border-radius: 4px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  &__footer {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 12px;
    align-items: center;
  }

  &__button,
  &__like,
  &__menu {
    width: 36px;
    height: 26px;
    border-radius: 4px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  &__button {
    width: 131px;
  }

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
}
</style>
