<script>
import { SuawModalTwo, SuawHeading, SuawButton } from "@/components";

export default {
  name: "SeriesSubscribeModal",
  components: { SuawModalTwo, SuawHeading, SuawButton },
  props: {
    value: Boolean,
    isSubscribed: {
      type: Boolean,
      required: true
    },
    isAuthenticated: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    modalTitle() {
      if (!this.isAuthenticated) {
        return "You must be signed in to subscribe to a series. You'll be redirected to the sign-in page.";
      }
      if (this.isSubscribed) {
        return "Are you sure you want to unsubscribe from this series? You will no longer receive email reminders or updates about upcoming events you haven’t RSVP’d to.";
      }
      return "By subscribing, you'll receive email reminders and updates about upcoming events in this series.";
    }
  },
  methods: {
    onCancel() {
      this.$emit("close");
    },
    onConfirm() {
      this.$emit("close");
      if (!this.isAuthenticated) {
        this.$emit("redirect-to-sign-in");
      } else {
        this.$emit(this.isSubscribed ? "unsubscribe" : "subscribe");
      }
    }
  }
};
</script>

<template>
  <SuawModalTwo :value="value" size="medium" @input="$emit('close')">
    <div class="modal-content">
      <SuawHeading level="4" :content="modalTitle" />
      <div class="modal-actions">
        <SuawButton class="modal-button" size="large" type="secondary-outline" icon-left="" button-text="Cancel" @click="onCancel" />
        <SuawButton class="modal-button" size="large" type="primary" icon-left="" :button-text="!isAuthenticated ? 'Sign In' : 'Confirm'" @click="onConfirm" />
      </div>
    </div>
  </SuawModalTwo>
</template>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-triple);
  text-align: center;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: var(--sem-space-triple);
}

.modal-button {
  width: 100%;
}
</style>
