<script>
export default {
  name: "ListItemSkeleton",
};
</script>

<template>
  <li class="suaw-list-item-skeleton">
    <div class="suaw-list-item-skeleton__avatar"></div>
    <div class="suaw-list-item-skeleton__content">
      <div class="suaw-list-item-skeleton__name"></div>
      <div class="suaw-list-item-skeleton__button"></div>
    </div>
  </li>
</template>

<style lang="scss" scoped>
.suaw-list-item-skeleton {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--date-square-shadow);
  border-radius: var(--sem-radius-sm);
  width: 100%;
  height: 74px;

  &__avatar,
  &__name,
  &__button {
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  &__avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex: 1;
  }

  &__name {
    width: 120px;
    height: 20px;
    border-radius: 4px;
  }

  &__button {
    width: 100px;
    height: 20px;
    border-radius: 4px;
  }

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
}
</style>
