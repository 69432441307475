<script>
import { SuawMainContent, SuawSection, SuawTabs, SuawSidebar, SuawEmptyState, SuawDivider, SuawAlert, SuawInputGroup } from "@/components";
import SeriesSummaryPipe from "../components/SeriesSummaryPipe.vue";
import EventCardPipe from "../components/EventCardPipe.vue";
import CalendarCardPipe from "../components/CalendarCardPipe.vue";
import EventDescription from "../components/EventDescription.vue";
import WritersSearch from "../components/WritersSearch.vue";
import EventNavigationPipe from "../components/EventNavigationPipe.vue";
import AgendaPipe from "../components/AgendaPipe.vue";
import DiscussionListPipe from "@/features/discussions/components/DiscussionListPipe.vue";
import OrganizerSidebar from "@/screens/chapterDetail/components/OrganizerSidebar.vue";
import EventRsvpedAndLastAttendeeList from "../components/EventRsvpedAndLastAttendeeList.vue";
import ChapterSidebar from "@/screens/chapterDetail/components/ChapterSidebar.vue";
import { GetEventAndEventSeriesDetailsByEventId, GetUserCheckInStatus } from "../../operations.gql";

export default {
  name: "Series",
  components: {
    SuawMainContent,
    SuawSection,
    SuawTabs,
    SuawSidebar,
    SuawEmptyState,
    WritersSearch,
    DiscussionListPipe,
    OrganizerSidebar,
    ChapterSidebar,
    SeriesSummaryPipe,
    SuawDivider,
    SuawAlert,
    SuawInputGroup,
    EventCardPipe,
    CalendarCardPipe,
    EventDescription,
    EventRsvpedAndLastAttendeeList,
    AgendaPipe,
    EventNavigationPipe
  },
  data() {
    return {
      loadingQueriesCount: 0,
      activeTabIndex: 0,
      resultGetEventAndEventSeriesDetailsByEventId: {
        id: "",
        starts_at: "",
        ends_at: "",
        event_series: {
          id: "",
          chapter_id: "",
          chapter: {
            slug: ""
          },
          organizers: [
            // {
            //   user_id: ""
            // }
          ]
        }
      },
      resultGetUserCheckInStatus: {
        id: "",
        event_calendars: [
          // {
          //   id: "",
          // }
        ]
      }
    };
  },
  apollo: {
    resultGetEventAndEventSeriesDetailsByEventId: {
      query: GetEventAndEventSeriesDetailsByEventId,
      variables() {
        return {
          eventId: this.$route.params.eventId
        };
      },
      skip() {
        return !this.$route.params.eventId;
      },
      loadingKey: "loadingQueriesCount"
    },
    resultGetUserCheckInStatus: {
      query: GetUserCheckInStatus,
      variables() {
        return {
          eventId: this.$route.params.eventId,
          userId: this.$auth.user.id
        };
      },
      skip() {
        return !this.$route.params.eventId || !this.$auth.isAuthenticated;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    canReport() {
      return this.$auth.isAuthenticated;
    },
    hasResponse() {
      return this.resultGetEventAndEventSeriesDetailsByEventId?.id !== "";
    },
    event() {
      return this.hasResponse ? this.resultGetEventAndEventSeriesDetailsByEventId : null;
    },
    startsAt() {
      return this.event?.starts_at || null;
    },
    endsAt() {
      return this.event?.ends_at || null;
    },
    joinTime() {
      const minutesBeforeStart = 5;
      if (this.startsAt) {
        const startAtDate = new Date(this.startsAt);
        if (!isNaN(startAtDate.getTime())) {
          const joinTime = new Date(startAtDate.getTime());
          joinTime.setMinutes(joinTime.getMinutes() - minutesBeforeStart);
          return joinTime;
        } else {
          this.$log.error("Invalid Date in joinTime calculation", this.startsAt);
          return null;
        }
      }
      return null;
    },
    // for when a user can join an online event
    isJoinTime() {
      const joinTime = this.joinTime;
      const endsAt = this.endsAt ? new Date(this.endsAt) : null;
      return joinTime && !isNaN(joinTime.getTime()) && endsAt && this.nowTimer >= joinTime && this.nowTimer <= endsAt;
    },
    // for when an organizer can check in members to an in-person event
    isAttendanceTime() {
      const startsAt = this.startsAt ? new Date(this.startsAt) : null;
      const endsAt = this.endsAt ? new Date(this.endsAt) : null;

      if (startsAt && endsAt) {
        const oneHourAfterEnd = new Date(endsAt.getTime());
        oneHourAfterEnd.setHours(oneHourAfterEnd.getHours() + 1);

        return this.nowTimer >= startsAt && this.nowTimer <= oneHourAfterEnd;
      }
      return false;
    },
    series() {
      return this.event?.event_series || null;
    },
    seriesId() {
      return this.series?.id || "";
    },
    organizerIds() {
      return this.series?.organizers?.map(org => org.user_id) || [];
    },
    channelId() {
      return this.series?.channel_id || null;
    },
    chapterIds() {
      return [this.series?.chapter_id];
    },
    tabs() {
      return [{ label: "Details" }, { label: "Members" }, { label: "Discussions" }];
    },
    hasCheckInStatus() {
      return this.resultGetUserCheckInStatus.id !== "";
    },
    userCheckedIn() {
      return this.hasCheckInStatus && this.resultGetUserCheckInStatus.event_calendars.length > 0;
    },
    isGlobalOnlineChapter() {
      return this.series?.chapter?.slug === "shutupandwriteonlineevents";
    }
  },
  mounted() {
    // Check query params on mount and emit error message if needed
    const { f, r } = this.$route.query;
    if (f === "true") {
      const message =
        r === "m"
          ? "We couldn't find the event you selected. Showing the latest available event in the series."
          : "An unexpected error occurred. Showing the latest available event in the series.";
      this.$root.$emit("universal-error-message", message);
    }

    this.$nextTick(() => {
      // Safely refetch main query
      if (this.$apollo.queries.resultGetEventAndEventSeriesDetailsByEventId) {
        this.$apollo.queries.resultGetEventAndEventSeriesDetailsByEventId.refetch();
      }

      // Safely refetch child component queries
      const refetchIfExists = (ref, queryName) => {
        if (this.$refs[ref]?.$apollo?.queries?.[queryName]) {
          this.$refs[ref].$apollo.queries[queryName].refetch();
        }
      };

      refetchIfExists("eventCard", "resultGetEventCardById");
      refetchIfExists("seriesSummary", "resultGetEventSeriesSummary");
      refetchIfExists("eventDescription", "resultGetEventDescriptionForDetailsById");
      refetchIfExists("calendarCard", "resultGetEventSeriesUpcomingEvents");
      refetchIfExists('organizerSidebar', 'resultGetOrganizersByIds');
    });
  },
  methods: {
    handleToggleJoin() {
      if (this.$refs.writersSearch?.$apollo?.queries) {
        this.$refs.writersSearch.$apollo.queries.resultGetEventSeriesWritersList?.refetch();
        this.$refs.writersSearch.$apollo.queries.resultGetCurrentEventRsvpedAndCheckedInList?.refetch();
        this.$refs.writersSearch.$apollo.queries.resultGetPreviousEventAttendeeList?.refetch();
      }
      if (this.$refs.eventCard?.$apollo?.queries?.resultGetUserRsvpAndFollowStatus) {
        this.$refs.eventCard.$apollo.queries.resultGetUserRsvpAndFollowStatus.refetch();
      }
    },
    handleToggleRsvp() {
      if (this.$refs.writersSearch?.$apollo?.queries) {
        this.$refs.writersSearch.$apollo.queries.resultGetEventSeriesWritersList?.refetch();
        this.$refs.writersSearch.$apollo.queries.resultGetCurrentEventRsvpedAndCheckedInList?.refetch();
        this.$refs.writersSearch.$apollo.queries.resultGetPreviousEventAttendeeList?.refetch();
      }
      if (this.$refs.seriesSummary?.$apollo?.queries?.resultGetUserEventSeriesMembershipStatus) {
        this.$refs.seriesSummary.$apollo.queries.resultGetUserEventSeriesMembershipStatus.refetch();
      }
    },
    handleOnlineCheckIn() {
      if (this.$apollo.queries.resultGetUserCheckInStatus) {
        this.$apollo.queries.resultGetUserCheckInStatus.refetch();
      }
    },
    handleEventSelected(event) {
      const query = !event.is_manifested ? { m: "false", d: event.starts_at, s: event.event_series_id } : {};
      this.$router.push({
        name: "Series",
        params: {
          chapterSlug: event.chapter_slug,
          seriesSlug: event.event_series_slug,
          eventId: event.id
        },
        query
      });
      //!! temporary solution to trigger manifestEventGuard
      if (!event.is_manifested) {
        window.location.reload();
      }
    },
    onSeeAllClick(activeTab) {
      this.activeTabIndex = activeTab;
      const suawTabsElement = this.$refs.suawTabs.$el;
      suawTabsElement.scrollIntoView({ behavior: "auto" });
    }
  }
};
</script>

<template>
  <SuawEmptyState v-if="!isLoading && !series" message="Event not found." />
  <SuawMainContent v-else size="large-small-gap">
    <SuawSection>
      <SeriesSummaryPipe ref="seriesSummary" :series-id="seriesId" @toggle-join="handleToggleJoin" />
    </SuawSection>
    <SuawSection>
      <SuawDivider />
    </SuawSection>
    <SuawSection v-if="userCheckedIn">
      <SuawAlert type="success" message="You've Checked In! Enjoy Your Event!" />
    </SuawSection>
    <SuawSection class="desktop">
      <SuawInputGroup direction="column" group-gap="quadruple" :prevent-flex-grow="true">
        <EventCardPipe
          ref="eventCard"
          :event-id="$route.params.eventId"
          :series-id="seriesId"
          :is-join-time="isJoinTime"
          @online-check-in="handleOnlineCheckIn"
          @toggle-rsvp="handleToggleRsvp"
        />
        <SuawTabs ref="suawTabs" v-model="activeTabIndex" :tabs="tabs">
          <template #tab-0>
            <EventDescription ref="eventDescription" :event-id="$route.params.eventId" />
            <AgendaPipe :series-id="seriesId" />
            <SuawDivider />
            <EventRsvpedAndLastAttendeeList :event-id="$route.params.eventId" :is-attendance-time="isAttendanceTime" @view-all-click="onSeeAllClick(1)" />
            <SuawDivider />
            <DiscussionListPipe
              v-if="channelId"
              :show-editor="false"
              :show-header="true"
              header-label="Recent Discussions"
              button-label="View All Discussions"
              :channel-id="channelId"
              :thread-count="2"
              :show-see-all="true"
              @view-all-click="onSeeAllClick(2)"
            />
          </template>
          <template #tab-1>
            <WritersSearch ref="writersSearch" :series-id="seriesId" :event-id="$route.params.eventId" :is-attendance-time="isAttendanceTime" />
          </template>
          <template #tab-2>
            <DiscussionListPipe v-if="channelId" :show-editor="true" :channel-id="channelId" :thread-count="0" header-text="Discussions" />
          </template>
        </SuawTabs>
      </SuawInputGroup>
      <SuawSidebar>
        <CalendarCardPipe ref="calendarCard" :series-id="seriesId" :event-id="$route.params.eventId" @event-selected="handleEventSelected" />
        <OrganizerSidebar ref="organizerSidebar" header-text="Organizers" :organizer-ids="organizerIds" />
        <ChapterSidebar v-if="!isLoading && !isGlobalOnlineChapter" header-text="Host Chapter" :chapter-ids="chapterIds" />
      </SuawSidebar>
    </SuawSection>

    <SuawSection class="tablet">
      <SuawInputGroup direction="column" group-gap="quadruple">
        <EventNavigationPipe ref="calendarCard" :series-id="seriesId" :event-id="$route.params.eventId" @event-selected="handleEventSelected" />
        <EventCardPipe
          ref="eventCard"
          :event-id="$route.params.eventId"
          :series-id="seriesId"
          :is-join-time="isJoinTime"
          @online-check-in="handleOnlineCheckIn"
          @toggle-rsvp="handleToggleRsvp"
        />
        <OrganizerSidebar ref="organizerSidebar" header-text="Organizers" :organizer-ids="organizerIds" />
        <ChapterSidebar v-if="!isLoading && !isGlobalOnlineChapter" header-text="Host Chapter" :chapter-ids="chapterIds" />
        <SuawTabs ref="suawTabs" v-model="activeTabIndex" tabs-type="buttons" :tabs="tabs">
          <template #tab-0>
            <EventDescription :event-id="$route.params.eventId" />
            <AgendaPipe :series-id="seriesId" />
            <SuawDivider />
            <EventRsvpedAndLastAttendeeList :event-id="$route.params.eventId" :is-attendance-time="isAttendanceTime" @view-all-click="onSeeAllClick(1)" />
            <SuawDivider />
            <DiscussionListPipe
              v-if="channelId"
              :show-editor="false"
              :channel-id="channelId"
              :thread-count="2"
              :show-see-all="true"
              header-text="Recent Discussions"
              @see-all-discussions="onSeeAllClick(2)"
            />
          </template>
          <template #tab-1>
            <WritersSearch :series-id="seriesId" :event-id="$route.params.eventId" />
          </template>
          <template #tab-2>
            <DiscussionListPipe v-if="channelId" :show-editor="true" :channel-id="channelId" :thread-count="0" header-text="Discussions" />
          </template>
        </SuawTabs>
      </SuawInputGroup>
    </SuawSection>
  </SuawMainContent>
</template>

<style lang="scss" scoped>
.desktop {
  @media (max-width: 1024px) {
    display: none;
  }
}
.tablet {
  display: none;
  @media (max-width: 1024px) {
    display: flex;
  }
}
</style>
