<script>
export default {
  name: "SeriesSummarySkeleton"
};
</script>

<template>
  <div class="skeleton-series-summary">
    <div class="skeleton-series-summary__heading-container">
      <div class="skeleton skeleton-series-summary__title"></div>
    </div>

    <div class="skeleton-series-summary__action-container">
      <!-- <div class="skeleton-series-summary__button-container">
        <div class="skeleton skeleton-series-summary__button"></div>
      </div> -->
      <div class="skeleton-series-summary__button-container">
        <div class="skeleton skeleton-series-summary__button"></div>
      </div>
    </div>

    <div class="skeleton-series-summary__info-container">
      <div class="skeleton skeleton-series-summary__chip"></div>
      <div class="skeleton skeleton-series-summary__chip member-chip"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.skeleton-series-summary {
  // height: 82px;
  max-width: 1264px;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 24px;
  row-gap: 8px;

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    row-gap: 16px;
  }

  &__heading-container {
    width: 100%;
  }

  &__title {
    width: 80%;
    height: 40px;
    border-radius: 4px;
  }

  &__action-container {
    display: inline-flex;
    justify-content: end;
    gap: 8px;

    @media (max-width: 768px) {
      grid-column: 2/3;
      grid-row: 1/3;
      flex-direction: column-reverse;
      justify-content: start;
      align-items: end;
    }

    @media (max-width: 650px) {
      grid-column: auto;
      grid-row: auto;
    }
  }

  &__button-container {
    @media (max-width: 768px) {
      justify-self: end;
    }

    @media (max-width: 650px) {
      width: 100%;
    }
  }

  &__button {
    width: 145px;
    height: 48px;
    border-radius: 8px;
    @media (max-width: 650px) {
      width: 100%;
    }
  }

  &__info-container {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 8px;

    @media (max-width: 768px) {
      grid-column: 1/2;
      grid-row: 2/3;
      align-items: center;
    }

    @media (max-width: 650px) {
      grid-column: auto;
      grid-row: 2/3;
    }
  }

  &__chip {
    width: 175px;
    height: 26px;
    border-radius: 16px;
  }

  /* Skeleton Effect */
  .skeleton {
    background: linear-gradient(90deg, #f4f4f4 25%, #eaeaea 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
    // border-radius: 4px;
  }

  .member-chip {
    width: 105px;
  }

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
}
</style>
